import styles from "./index.module.css"

const Index = (props) => {
    return (
        <div>
            <div className={styles.div}>
                Powered by 客满房（成都）科技股份有限公司 <a href="https://beian.miit.gov.cn" target="_blank" className={styles.beian}>黔ICP备2021008283号</a>
            </div>
            <div className={styles.div2}>
                <a href="https://www.kemanfang.net/admin" target="_blank" className={styles.beian}>管理入口</a>
            </div>
        </div>
    );
}

export default Index;
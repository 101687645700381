import styles from "./index.module.css"
// import qrcode_xcx from "../../resource/xcx.jpg"

const Index = (props) => {
    return ( 
        <div id="xcx" className={styles.div}>
            <div className={styles.left}>
                <img src="img/xcx.jpg"/>
            </div>
            <div className={styles.right}>
                扫码进入小程序查看所有酒店项目
            </div>
        </div>
     );
}
 
export default Index;